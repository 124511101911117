.header-design {
    width: 100%;
    height: 100px;
    display: flex;
    position: relative;
  }
  
  .red-section {
    width: 40%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .red-large {
    width: 50%;
    height: 20px;
    background-color: var(--secondry-color);
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
    transform: scaleY(-1);
    margin-top: 10px;
  }
  
  .red-medium {
    width: 80%;
    height: 20px;
    background-color: var(--secondry-color);
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
    margin-top: 10px;
    transform: scaleY(-1);
  }
  
  .red-small {
    width: 95%;
    height: 40px;
    background-color: var(--secondry-color);
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
    margin-top: 5px;
    transform: scaleY(-1);
  }
  
  .black-section {
    width: 60%;
    background-color: var(--primary-color);
    clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
    transform: scaleY(-1);
  }
  .header-design-bottom {
    width: 100%;
    height: 100px;
    display: flex;
    position: relative;
  }
  
  .red-section-bottom {
    width: 40%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .red-large-bottom {
    width: 50%;
    height: 20px;
    background-color: var(--secondry-color);
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
    transform: scaleX(-1);
  
    margin-top: 10px;
  }
  
  .red-medium-bottom {
    width: 70%;
    height: 20px;
    background-color: var(--secondry-color);
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
    margin-top: 10px;
    transform: scaleX(-1);
  }
  
  .red-small-bottom {
    width: 95%;
    height: 40px;
    background-color: var(--secondry-color);
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
    margin-top: 5px;
    transform: scaleX(-1);
  }
  
  .black-section-bottom {
    width: 60%;
    background-color: var(--primary-color);
    clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
    transform: scaleX(-1);
  }
  .invoice-header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: 20px;
      padding: 20px;
      /* max-width: 900px; */
      /* margin: auto; */
  }
  
  .company-info {
      display: flex;
      flex-direction: column;
      gap: 5px;
  }
  .invoice-details .address-header{
    padding-bottom: 15px;
    font-weight: 700;
  }
  .company-logo {
      display: flex;
      align-items: center;
      gap: 10px;
  }
  
  .company-logo img {
      width: 100px;
      height: 100px;
  }
  
  .company-name {
      font-size: 22px;
      font-weight: bold;
      color: var(--text-color);
  }
  
  .invoice-details {
      text-align: right;
  }
  
  .invoice-title {
      font-size: 40px;
      font-weight: bold;
      color: var(--text-color);
      margin-bottom: 10px;
      padding-bottom: 20px;
  }
  
  
  .company-info p {
      color: var(--text-color);
      line-height: 1.4;
  }
  
  .bold {
      font-weight: bold;
      color: var(--text-color);
  }
  
  /* Responsive */
  @media (max-width: 768px) {
      .invoice-header {
          grid-template-columns: 1fr;
          text-align: center;
      }
  
      .invoice-details {
          text-align: center;
      }
  }
  .user-details{
      margin-top: 30px;
  }
  .user-details .email {
    font-weight: 800
  }
  
  /* General styling for the table */
  .invoice-table {
    width: 100%; /* Full width */
    border-collapse: collapse; /* Collapse borders for a clean look */
    margin-top: 20px; /* Spacing at the top */
  }
  /* table {
    width: 100%;
  } */
  /* Styling for table headers */
  .invoice-table th {
    padding: 12px;
    border: 1px solid #ddd; /* Light border for readability */
    background-color: #f4f4f4; /* Light gray background */
    text-align: left;
    font-weight: bold;
  }
  .invoice-table th .des{
    width: 40%;
  }
  /* Styling for table rows and cells */
  .invoice-table td {
    padding: 12px;
    border: 1px solid #ddd;
    vertical-align: top;
    text-align: left; /* Align text to the left */
  }
  
  /* Bold text styling */
  .bold {
    font-weight: bold;
  }
  
  /* Alternate row color for better readability */
  .invoice-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  /* Responsiveness: Make sure text doesn't overflow */
  .invoice-table td {
    word-wrap: break-word; /* Allow long text to wrap */
    overflow-wrap: break-word;
  }
  
  /* Ensure the table takes the full width of its container */
  .invoice-table {
    width: 100%;
    max-width: 100%;
  }
  
  /* Optional: Adjust column widths if needed */
  
  