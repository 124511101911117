/* Container */
.order-view-container {
  padding: 24px;
  font-family: "Inter", sans-serif;
  background: #f4f6f9;
  border-radius: 10px;
}

/* Order Header */
.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
}

.order-header div {
  flex: 1;
}

.order-header p {
  font-size: 14px;
  color: #555;
  margin: 4px 0;
}

.order-header b {
  color: #222;
}

/* Section Layout */
.order-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

/* Address Cards */
.address-card {
  flex: 1;
  min-width: 300px;
  background: #ffffff;
  padding: 18px;
  border-radius: 12px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
}

.address-card h4 {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 12px;
  padding-bottom: 6px;
  border-bottom: 2px solid #eee;
}

.address-card p {
  font-size: 14px;
  color: #555;
  line-height: 1.6;
}

/* Purchase List */
.purchase-list {
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
  overflow-x: auto;
}

.purchase-list h4 {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
  color: #222;
}

/* Table */
.order-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.order-table thead {
  background: #f8f9fb;
}

.order-table th,
.order-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.order-table th {
  font-size: 14px;
  font-weight: bold;
  color: #444;
}

.order-table tbody tr:nth-child(even) {
  background: #f9f9f9;
}

.order-table tbody tr:hover {
  background: #f0f0f0;
}

/* Order Summary */
.order-summary {
  margin-top: 24px;
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
}

.order-summary p {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin: 8px 0;
}

/* Status Pill Badges */
.status-pill {
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
}

.status-processing {
  background: #ffeb3b;
  color: #333;
}

.status-pending {
  background: #ff9800;
  color: #fff;
}

.status-completed {
  background: #4caf50;
  color: #fff;
}
