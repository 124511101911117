.product-view-container {
  margin-top: 20px;
}

.carousel-image:hover {
  transform: scale(1.1);
}

.product-details {
  padding: 20px;
}

.product-name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.product-price {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.sales-price {
  text-decoration: line-through;
  color: #888;
  margin-left: 10px;
}

.product-description,
.product-specification {
  margin-bottom: 10px;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Image Grid Container */
.image-grid-view {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
  margin-top: 20px;
  padding: 0 10px;
}

/* Grid Item */
.image-grid-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Image Styling */
.grid-image {
  width: 100%;
  height: 400px; /* Match thumbnail height */
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

/* Hover Effect */
.grid-image:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
