.search-container {
  position: relative;
  width: 450px;
}

.search-input:focus {
  border-color: #007bff !important;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.search-dropdown {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.search-dropdown-item {
  transition: all 0.3s ease-in-out;
}

.search-dropdown-item:hover {
  background: #f8f9fa;
  color: #007bff;
}

.search-dropdown-item:active {
  background: #007bff;
  color: white;
}

.search-dropdown-item.Mui-selected {
  background-color: #007bff !important;
  color: white;
}
