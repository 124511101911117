/* Sidebar Container */
.sidebar {
  width: 250px;
  background: rgb(8 97 181);
  max-height: 100vh;
  display: flex;
  color: #f5f1f1;
  overflow: auto;
  flex-direction: column;
  padding: 15px 17px 5px;
  transition: width 0.3s ease-in-out;
}

/* Customize sidebar scrollbar */
.sidebar::-webkit-scrollbar {
  width: 4px; /* Make it very thin */
}

.sidebar::-webkit-scrollbar-track {
  background: transparent; /* Remove background */
}

.sidebar::-webkit-scrollbar-thumb {
  background: #b8b8b8; /* Subtle color for the scrollbar */
  border-radius: 10px; /* Make it rounded */
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: #f8f8f8; /* Slightly darker on hover */
}

.sidebar.closed {
  width: 10px;
}

/* Sidebar Header */
.sidebar-header {
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize !important;
  padding: 1px 10px;
  color: #e0dddd;
}

/* Sidebar Sections */

.sidebar-heading {
  padding: 15px 20px;
  text-transform: capitalize;
  font-size: 13px;
  padding: 10px;
  color: #a7aaaccc;
  text-transform: uppercase;
}

/* Menu Items */
.sidebar-menu {
  list-style: none;
  padding: 0;
}

.menu-item {
  list-style: none;
  transition: all 0.3s ease-in-out;
}

/* Menu Links */
.menu-link {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 14px;
  color: #fffefe;
  text-decoration: none;
  transition: background 0.2s ease-in-out;
}

.menu-icon {
  margin-right: 10px;
}

/* Dropdown Accordion */
.menu-dropdown {
  background: none;
}

.menu-title {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;
}

.menu-sub-items {
  list-style: none !important;
  text-decoration: none !important;
  overflow: hidden;
  margin-bottom: 5px;
  padding-left: 1.5rem;
  transition: max-height 0.3s ease-in-out;
}

.arrow-icon {
  margin-left: auto;
  transition: transform 0.3s ease-in-out;
}

.active-dropdown .arrow-icon {
  transform: rotate(180deg);
}

.menu-sub-active-Class {
  background-color: rgb(255, 215, 0) !important;
  color: black !important;
  border-radius: 2px;
  cursor: pointer;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out,
    transform 0.3s ease-in-out;

  /* Smooth fade-in effect */
  opacity: 1;
  transform: translateX(0);
}

.menu-sub-inactive-Class {
  background-color: rgb(52, 73, 94);
  color: white;
  border-radius: 2px;
  cursor: pointer;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out,
    transform 0.3s ease-in-out;

  /* Smooth fade-out effect */
  opacity: 0.8;
  transform: translateX(-10px);
}

.menu-sub-active-Class:hover {
  transform: scale(1.05);
}
