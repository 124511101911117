.dashboard-container-xyz {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.sidebar-xyz {
  width: 250px;
  color: #181717;
  background-color: #e0e8f1;
  height: 75vh;
  overflow: auto;
  padding: 10px;
  transition: width 0.3s ease, box-shadow 0.3s ease;
}

.sidebar-content-xyz {
  display: flex;
  flex-direction: column;
}

.sidebar-item-xyz {
  padding: 12px 20px;
  margin-bottom: 12px;
  cursor: pointer;
  border-radius: 2px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.sidebar-item-xyz:hover {
  background-color: #297fb956;
  color: #ffffff;
  transform: translateX(5px);
}

.active-sidebar-item-xyz {
  background-color: #2980b9;
  color: #e6f2ff;
  font-weight: bold;
}

.sidebar-icon-xyz {
  font-size: 24px;
  margin-right: 12px;
  transition: transform 0.2s ease;
}

.sidebar-item-xyz:hover .sidebar-icon-xyz {
  transform: rotate(10deg);
}

/* Main Content Area */
.main-content-xyz {
  flex-grow: 1;
  height: 75vh;
  overflow: auto;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Card Styling */
.settings-card-xyz {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease-in-out;
}

.settings-title-xyz {
  font-size: 26px;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  color: #3498db;
  transition: color 0.3s ease;
}

.settings-title-xyz:hover {
  color: #2980b9;
}

.settings-row-xyz {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease, transform 0.2s ease;
  position: relative;
}

.settings-row-xyz:hover {
  transform: translateY(-5px);
}

.settings-input-xyz {
  width: 80%;
  margin-right: 20px;
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.settings-input-xyz:focus {
  border-color: #3498db;
}

.toggle-status-group-xyz {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
}

.toggle-status-group-xyz .MuiToggleButton-root:hover {
  transform: scale(1.1);
}

.button-group-xyz {
  display: flex;
  gap: 12px;
}

.edit-btn-xyz:hover {
  background-color: #2980b9;
}

.save-btn-xyz {
  background-color: #2ecc71;
  color: white;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.save-btn-xyz:hover {
  background-color: #27ae60;
}

.cancel-btn-xyz {
  background-color: #e74c3c;
  color: white;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.cancel-btn-xyz:hover {
  background-color: #c0392b;
  color: white;
}

.main-content-placeholder-xyz {
  text-align: center;
  font-size: 18px;
  color: #7f8c8d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.main-content-placeholder-xyz:hover {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
}

/* Container holding the tab content */
.tab-content-container {
  position: relative;
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

/* Fade-in animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Slide-in animation (alternative to fade-in) */
@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.tab-content-container.slide-in {
  animation: slideIn 0.5s ease-out forwards;
}

/* Darkened background effect when hovering */
.settings-row-xyz.hovered::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Dark background */
  z-index: 1; /* Lower z-index to prevent blocking */
  transition: background-color 0.3s ease;
}

/* Ensure the Edit button is not affected by the dark background */
.settings-row-xyz .edit-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0; /* Initially hidden */
  z-index: 2 !important; /* Higher than the background */
  transition: opacity 0.3s ease;
  pointer-events: auto; /* Ensure it's clickable */
}

/* When hovering over the box, show the Edit button */
.settings-row-xyz.hovered .edit-btn {
  opacity: 1 !important;
}

/* Ensure the entire row remains clickable */
.settings-row-xyz {
  position: relative;
  z-index: 2; /* Keep it above the background */
}

/* When editing, remove the dark background */
.settings-row-xyz.editing::before {
  background-color: transparent !important;
  z-index: -1; /* Push the background behind everything */
}

/* When hovering over the box, show the Edit button */
.settings-row-xyz.hovered .edit-btn {
  opacity: 1 !important; /* Make sure the Edit button is visible */
}

/* The container for Save and Cancel buttons, hidden initially */
.button-group {
  display: none;
  gap: 10px;
  margin-top: 10px;
}

/* When the row is in editing mode, show the buttons */
.settings-row-xyz.editing .button-group {
  display: flex;
}

/* Style the Save and Cancel buttons */
.button-group button {
  padding: 5px 15px;
  font-size: 14px;
}

/* Remove the dark background when editing */
.settings-row-xyz.editing::before {
  background-color: transparent !important;
}
