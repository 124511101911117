@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #eeefef;
}

html {
  background: #eeefef;
}

.btn,
/* button, */
select,
input {
  border-radius: 0px !important;
}

.dm-sans {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
}

.poppins {
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

.roboto {
  font-family: "Roboto", sans-serif;
  font-style: normal;
}

.dc-script {
  font-family: "Dancing Script", cursive;
  font-style: normal;
}

.flex-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.flex-col-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}

.btn-none {
  border: none;
  outline: none;
  background: transparent;
}

.no-border-btn {
  color: black;
  transition: 0.5s;
  display: flex;
  gap: 10px;
  align-items: center;
  width: max-content;
  text-decoration: none;
}

.no-border-btn:hover {
  border-bottom: 1px solid #999;
  transition: 0.5s;
}

.font-bold {
  font-weight: 900;
}

.text-grey {
  color: #999;
}

.outline-none {
  outline: none;
}

.h-dvh {
  height: 100vh;
}

.bg-none {
  background: none;
}

.fs-6px {
  font-size: 6px !important;
}

.fs-8 {
  font-size: 8px;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.color-red {
  color: red;
}

.bg-active {
  background: #1b80ce;
}

.text-active {
  background: #1b80ce;
}

label,
.label {
  color: #1b80ce;
  font-weight: 900;
}

.light-white {
  color: rgba(255, 255, 255, 0.8);
}

.hover-white:hover {
  color: #fff !important;
}

.hover-black:hover {
  color: #000 !important;
}

.gray-border {
  border-color: gray;
}

textarea,
.description_textarea {
  scrollbar-width: thin;
  resize: none;
}

.blue-color {
  color: #1467a5 !important;
}

.btn {
  background-color: #1467a5 !important;
}

.bg-main {
  background: rgb(238, 239, 239);
}

.dropdown button,
select {
  color: #5b6262 !important;
}

.common_input {
  border: 1px solid rgb(226, 228, 228);
}

.text-right {
  text-align: right;
}

.modal-content {
  border-radius: 0px !important;
}

/* +++++++++++++++++++++++++Login Page */

.admin-login-container {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.admin-login-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  padding: 20px;
  min-width: 400px;
  /* Ensure minimum width */
}

.Paasword {
  border-radius: 0px !important;
}

.admin-logo {
  margin-left: 20px !important;
}

.admin-login-slider {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.slider-container {
  position: absolute;
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 200px;
}

.slide {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
}

.slide.active {
  display: block;
  animation: fade-in 1s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.dots {
  position: absolute;
  bottom: 20px;
  display: flex;
  gap: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}

.dot.active {
  background-color: rgba(255, 255, 255, 1);
}

@media (max-width: 768px) {
  .admin-login-container {
    flex-direction: column;
  }

  .admin-login-form,
  .admin-login-slider {
    flex: none;
    width: 100%;
    height: 50%;
  }
}

.admin-login-slider {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  color: rgb(43, 40, 40);
  text-align: center;
  padding: 20px;
}

/* .slider-container {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
} */

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #717171;
}

.slider-text {
  margin-top: 800px;
}

.slider-text h2 {
  color: #454546;
  font-size: 23px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-text p {
  color: rgb(163, 159, 159);
  font-size: 13px;
  margin-top: 10px;
}

.text-muted {
  font-size: 13px;
}

p.text-end .text-decoration-none {
  text-decoration: none;
}

.googlebutton {
  border: 1px solid rgb(173, 171, 171) !important;
}

/* **********imageuploader */
/* styles.css */
.image-uploader {
  border: 2px dashed #ccc;
  /* Dashed border */
  border-radius: 8px;
  /* Rounded corners */
  padding: 20px;
  text-align: center;
  transition: border-color 0.3s;
  background-color: #f9f9f9;
  /* Light background */
}

.image-uploader:hover {
  border-color: #007bff;
  /* Change border color on hover */
}

.image-preview {
  max-width: 100%;
  /* Responsive image */
  max-height: 200px;
  /* Limit height */
  border-radius: 4px;
  /* Rounded corners for image */
  margin-top: 10px;
  /* Spacing above the image */
}

.InnerSide-activeTab {
  color: #ffffff;
  background-color: #1864a4;
  margin-bottom: 20px;
}

.ck-editor__editable {
  height: 150px;
  overflow: auto;
}

.error-text {
  color: red;
  /* Set the text color to red for error messages */
  font-size: 12px;
  /* Adjust the font size if necessary */
  margin-top: 4px;
  /* Space between the input and error message */
}

/* *************Media libary css */
.styled-image {
  border: "none " !important;
  height: 210px;
  transition: transform 0.3s;
}

.styled-image:hover {
  transform: scale(1.05);
}

.custom-select {
  border: 1px solid #ccc;
  /* Add border */
  border-radius: 4px !important;
  /* Rounded corners */
  padding: 10px;
  /* Padding for the dropdown */
  background-color: white;
  font-size: 16px;
  height: 50px;
  /* Font size */
  transition: border-color 0.3s ease;
  /* Smooth border transition */
  width: 100%;
  /* Make the select full width */
}

.custom-select:focus {
  outline: none;
  /* Remove default outline */
  border-color: #007bff;
  /* Change border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  /* Add shadow on focus */
}

.custom-label {
  font-weight: bold;
  /* Make label bold */
  margin-bottom: 5px;
  /* Spacing below label */
}

.error-message {
  font-size: 14px;
  /* Font size for error message */
  color: red;
  /* Error text color */
  margin-top: 5px;
  /* Spacing above error message */
}

/* Hover Effect to show the delete icon */
.product-image-item:hover .delete-icon {
  opacity: 1;
}
.actions-buttons {
  display: flex;
  justify-content: space-evenly;
}
.pointer {
  cursor: pointer;
}

/* 
*********** */
/* styles/responsive.css */

.device-restricted {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 9999;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #333;
}

@media (min-width: 1025px) {
  .device-restricted {
    display: none;
  }
}
